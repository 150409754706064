<template>
  <v-container fluid>
    <v-progress-linear
        class="pt-0"
        :active="isUserDataLoading"
        indeterminate
        color="teal"
        height="3"
        absolute
        bottom
    />
    <v-container fluid v-if="getQueryParam('contextKey')">
      <v-row>
        <v-col>
          <v-sheet
              rounded="lg"
              class="bg-grey-lighten-3"
          >
            <v-row
                align="center"
                justify="center"
                dense
            >
              <v-col cols="12">
                <v-card variant="flat">
                  <v-list-item
                      class="px-6"
                      height="88"
                  >
                    <template #prepend>
                      <v-avatar
                          color="surface-light"
                          size="32"
                      >
                        🔧
                      </v-avatar>
                    </template>

                    <template #title>
                      Настройка параметров
                    </template>
                    <template #subtitle>
                      Выбор документов для синхронизации с модулем 1С
                    </template>
                    <template #append>
                      <v-btn @click="loadDefaultSettings()" variant="tonal" class="mr-2"
                             v-tooltip="'Восстановить прежние настройки'">
                        <v-icon left>
                          mdi-restore
                        </v-icon>
                        <span class="hidden-sm-and-down"></span>
                      </v-btn>

                      <v-btn-toggle
                          v-model="btnGroup"
                          density="compact"
                          multiple
                          class="mr-2"
                          border
                          color="teal-darken-1"
                      >
                        <v-btn value="load" @click="loadAll()">
                          <v-icon left class="mr-2">
                            mdi-package-variant-closed
                          </v-icon>
                          <span class="hidden-sm-and-down">Загружать все в 1С</span>
                        </v-btn>
                        <v-divider vertical></v-divider>
                        <v-btn value="unload" @click="unloadAll()">
                          <span class="hidden-sm-and-down">Выгружать все с 1С</span>

                          <v-icon right class="ml-2">
                            mdi-package-variant
                          </v-icon>
                        </v-btn>
                      </v-btn-toggle>

                      <v-dialog
                          v-model="dialog"
                          max-width="600"
                          persistent
                      >
                        <template #activator="{ props: activatorProps }">
                          <v-btn
                              v-bind="activatorProps"
                              color="indigo"
                              variant="flat"
                          >
                            Сохранить настройки
                          </v-btn>
                        </template>

                        <v-card
                            variant="flat"
                            text="Уважаемый пользователь, изменение настроек повлечёт за собой влияение только на следующий запуск синхронизации в 1С"
                            title="Вы уверены, что хотите сохранить настройки?"
                        >
                          <template #prepend>
                            <v-icon
                                color="red-accent-3"
                                icon="mdi-alert"
                            />
                          </template>
                          <v-divider/>
                          <template #actions>
                            <v-spacer/>
                            <v-btn
                                :disabled="loading"
                                variant="plain"
                                @click="dialog = false"
                            >
                              Отмена
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                :loading="loading"
                                color="indigo"
                                variant="flat"
                                @click="updateSettings"
                            >
                              Сохранить настройки
                            </v-btn>
                          </template>
                        </v-card>
                      </v-dialog>
                    </template>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>

            <v-row dense>
              <!-- First Table -->
              <v-col
                  cols="12"
                  md="12"
                  lg="6"
                  sm="12"
              >
                <v-card flat>
                  <v-card-title class="d-flex align-center pe-2">
                    Настройки документов
                    <v-spacer/>

                    <v-text-field
                        v-model="documentTableSearch"
                        prepend-inner-icon="mdi-magnify"
                        variant="solo-filled"
                        clearable
                        flat
                        density="compact"
                        placeholder="Поиск"
                        hide-details
                    />
                  </v-card-title>
                  <v-divider/>
                  <v-data-table-virtual
                      v-model:search="documentTableSearch"
                      :items="documentTableItems"
                      :headers="documentTableHeaders"
                      :group-by="documentTableGroupBy"
                      fixed-header
                      :items-per-page="-1"
                      height="75vh"
                      density="comfortable"
                      hide-default-footer
                      no-data-text="Данные не найдены, попробуйте задать другие параметры поиска"
                  >
                    <template #group-header="{ item, columns, toggleGroup, isGroupOpen }">
                      <template
                          :ref="() => { groupHeaders[item.value] = { item, toggleGroup, isGroupOpen } }"
                      />
                      <tr>
                        <td :colspan="columns.length">
                          <v-btn
                              :icon="isGroupOpen(item) ? '$expand' : '$next'"
                              size="small"
                              variant="text"
                              @click="toggleGroup(item)"
                          />
                          {{ item.value }} ({{ item.items.length }})
                        </td>
                      </tr>
                    </template>

                    <template #[`item.nameOneC`]="{ item }">
                      <div v-html="item.nameOneC"/>
                    </template>

                    <template #[`item.load`]="{ item }">
                      <v-switch
                          :model-value="item.load"
                          color="teal-darken-1"
                          class="mx-12"
                          hide-details
                          @change="onSwitchChange('document-settings',item, true)"
                      />
                    </template>

                    <template #[`item.unload`]="{ item }">
                      <v-switch
                          v-if="item.hasOwnProperty('unload')"
                          :model-value="item.unload"
                          color="teal-darken-1"
                          class="mx-12"
                          hide-details
                          @change="onSwitchChange('document-settings',item,false)"
                      />
                    </template>
                  </v-data-table-virtual>
                </v-card>
              </v-col>

              <!-- Second Table -->
              <v-col
                  cols="12"
                  md="12"
                  lg="6"
                  sm="12"
              >
                <v-card flat>
                  <v-card-title class="d-flex align-center pe-2">
                    Настройки справочников
                    <v-spacer/>

                    <v-text-field
                        v-model="referenceTableSearch"
                        prepend-inner-icon="mdi-magnify"
                        variant="solo-filled"
                        flat
                        density="compact"
                        placeholder="Поиск"
                        clearable
                        hide-details
                    />
                  </v-card-title>
                  <v-divider/>
                  <v-data-table-virtual
                      v-model:search="referenceTableSearch"
                      :items="referenceTableItems"
                      :headers="referenceTableHeaders"
                      fixed-header
                      :items-per-page="-1"
                      height="75vh"
                      density="comfortable"
                      hide-default-footer
                      no-data-text="Данные не найдены, попробуйте задать другие параметры поиска"
                  >
                    <template #[`item.nameOneC`]="{ item }">
                      <div v-html="item.nameOneC"/>
                    </template>

                    <template #[`item.load`]="{ item }">
                      <v-switch
                          :model-value="item.load"
                          color="teal-darken-1"
                          class="mx-12"
                          hide-details
                          @change="onSwitchChange('reference-settings',item, true)"
                      />
                    </template>

                    <template #[`item.unload`]="{ item }">
                      <v-switch
                          v-if="item.hasOwnProperty('unload')"
                          :model-value="item.unload"
                          color="teal-darken-1"
                          class="mx-12"
                          hide-details
                          @change="onSwitchChange('reference-settings',item,false)"
                      />
                    </template>
                  </v-data-table-virtual>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-empty-state
          headline="Загружаем данные"
          title="Обычно это происходит очень быстро"
          text="Если вы столкнулись с проблемой, напишите нам в @tg:siriuslab.support.com"
          image="https://vuetifyjs.b-cdn.net/docs/images/logos/v.png"
      ></v-empty-state>
    </v-container>

  </v-container>
</template>

<script setup>
import {ref, onMounted} from 'vue'

const groupHeaders = ref([])

onMounted(() => {
  Object.values(groupHeaders.value).forEach(header => {
    header.toggleGroup(header.item)
  })
})
</script>


<script>
import {getQueryParam} from '@/utils/utils';
import ApiService from '@/api/api';

export default {
  data: () => ({
    btnGroup: [],
    isUserDataLoading: false,
    tokenSnackbarVisible: false,
    settings: [],
    basic: {
      username: 'aB9dXr5vQj2Z',
      password: '^pL7k!rT3*Qz&8W'
    },
    loading: false,
    dialog: false,
    documentTableSearch: '',
    documentTableHeaders: [
      {title: 'Группа', key: 'data-table-group'},
      {title: 'Наименование документа', key: 'nameMs'},
      {title: 'Документ в 1С', key: 'nameOneC'},
      {title: 'Загружать с МС в 1С', key: 'load'},
      {title: 'Выгружать с 1С в МС', key: 'unload'},
    ],
    documentTableGroupBy: [
      {
        key: 'category',
        order: 'asc',
      },
    ],
    documentTableItems: [
      {
        name: 'customerOrder',
        category: 'Продажи',
        nameMs: 'Заказы',
        nameOneC: 'Счет на оплату',
        load: false
      },
      {
        name: 'demand',
        category: 'Продажи',
        nameMs: 'Отгрузки',
        nameOneC: 'Реализация ТМЗ и Услуг',
        load: false,
        unload: false,
      },
      {
        name: 'salesReturn',
        category: 'Продажи',
        nameMs: 'Возвраты покупателей',
        nameOneC: 'Возврат ТМЗ от покупателя',
        load: false,
        unload: false,
      },
      {
        name: 'supply',
        category: 'Закупки',
        nameMs: 'Приемки',
        nameOneC: 'Поступление ТМЗ и услуг',
        load: false,
        unload: false,
      },
      {
        name: 'purchaseReturn',
        category: 'Закупки',
        nameMs: 'Возвраты поставщикам',
        nameOneC: 'Возврат ТМЗ поставщику',
        load: false
      },
      {
        name: 'purchaseOrder',
        category: 'Закупки',
        nameMs: 'Заказы поставщику',
        nameOneC: 'Заказ поставщику',
        load: false
      },
      {
        name: 'enter',
        category: 'Товары',
        nameMs: 'Оприходования',
        nameOneC: 'Оприходование ТМЗ',
        load: false
      },
      {
        name: 'loss',
        category: 'Товары',
        nameMs: 'Списания',
        nameOneC: 'Списание ТМЗ',
        load: false
      },
      {
        name: 'move',
        category: 'Товары',
        nameMs: 'Перемещения',
        nameOneC: 'Перемещение ТМЗ',
        load: false
      },
      {
        name: 'paymentIn',
        category: 'Платежи',
        nameMs: 'Входящие',
        nameOneC: 'ППВ',
        load: false,
        unload: false,
      },
      {
        name: 'paymentOut',
        category: 'Платежи',
        nameMs: 'Исходящие',
        nameOneC: 'ППИ',
        load: false,
        unload: false,
      },
      {
        name: 'cashIn',
        category: 'Платежи',
        nameMs: 'Приходный кассовый ордер',
        nameOneC: 'ПКО',
        load: false,
        unload: false,
      },
      {
        name: 'cashOut',
        category: 'Платежи',
        nameMs: 'Расходный кассовый ордер',
        nameOneC: 'РКО',
        load: false,
        unload: false,
      },
      /*{
        name: 'processing',
        category: 'Производство',
        nameMs: 'Тех. Операции',
        nameOneC: 'Производственные документы<br>(Требование накладная,<br> Отчет о производстве за смену)',
        load: false
      }*/
    ],

    referenceTableSearch: '',
    referenceTableHeaders: [
      {title: '№', key: 'num'},
      {title: 'Наименование документа', key: 'nameMs'},
      {title: 'Справочник в 1С', key: 'nameOneC'},
      {title: 'Загружать с МС в 1С', key: 'load'},
      {title: 'Выгружать с 1С в МС', key: 'unload'},
    ],
    referenceTableItems: [
      {
        num: '1',
        name: 'good',
        nameMs: 'Товары',
        nameOneC: 'Номенклатура',
        load: false,
        unload: false,
      },
      {
        num: '2',
        name: 'company',
        nameMs: 'Контрагенты',
        nameOneC: 'Контрагенты',
        load: false,
        unload: false,
      },
      {
        num: '3',
        name: 'contract',
        nameMs: 'Договоры',
        nameOneC: 'Договоры контрагентов',
        load: false,
        unload: false,
      },
      /*{
        num: '4',
        name: 'processingPlan',
        nameMs: 'Тех. карты',
        nameOneC: 'Спецификации номенклатур',
        load: false
      },*/
      {
        num: '4',
        name: 'bundle',
        nameMs: 'Комплекты',
        nameOneC: 'Комплектация ТМЗ или <br>Отчет производства за смену',
        load: false
      }
    ]
  }),

  async created() {
    await this.loadUserData();
  },

  methods: {
    async loadDefaultSettings() {
      this.btnGroup = []
      this.documentTableItems.forEach(item => {
        item.load = false
        if (Object.hasOwn(item, 'unload')) {
          item.unload = false
        }
      });
      this.referenceTableItems.forEach(item => {
        item.load = false
        if (Object.hasOwn(item, 'unload')) {
          item.unload = false
        }
      });
      await this.loadUserData();
    },

    toggleBtnGroup() {
      const areAllItemsTrue = (array, key) => array.every(item => item[key] === true || item[key] === undefined);

      const allDocumentsValidLoad = areAllItemsTrue(this.documentTableItems, "load");
      const allReferenceValidLoad = areAllItemsTrue(this.referenceTableItems, "load");
      if (allDocumentsValidLoad && allReferenceValidLoad) {
        this.btnGroup = [...this.btnGroup, "load"];
      }

      const allDocumentsValidUnload = areAllItemsTrue(this.documentTableItems, "unload");
      const allReferenceValidUnload = areAllItemsTrue(this.referenceTableItems, "unload");
      if (allDocumentsValidUnload && allReferenceValidUnload) {
        this.btnGroup = [...this.btnGroup, "unload"];
      }
    },

    async loadUserData() {
      this.isUserDataLoading = true;
      const contextKey = getQueryParam('contextKey');
      if (contextKey) {
        try {
          const response = await ApiService.getUserContext(contextKey, this.basic.username, this.basic.password);
          sessionStorage.setItem('userData', JSON.stringify(response));

          // Extract settings from userData
          const settings = response.settings;

          // Update documentTableItems based on settings
          this.documentTableItems = this.documentTableItems.map(item => {
            const updatedItem = {
              ...item,
              load: settings[item.name] || item.load
            };

            if ('unload' in item) {
              updatedItem.unload = settings[`${item.name}In`] || item.unload;
            }

            return updatedItem;
          });

          // Update documentTableItems based on settings
          this.referenceTableItems = this.referenceTableItems.map(item => {
            const updatedItem = {
              ...item,
              load: settings[item.name] || item.load
            };

            if ('unload' in item) {
              updatedItem.unload = settings[`${item.name}In`] || item.unload;
            }

            return updatedItem;
          });
          this.isUserDataLoading = false;
          this.toggleBtnGroup();
        } catch (error) {
          this.isUserDataLoading = false;
          console.error('Failed to load user context', error);
        }
      } else {
        console.error("No contextKey found in URL");
      }
    },

    onSwitchChange(settings, item, isLoad) {
      // Toggle the appropriate property based on isLoad
      if (isLoad) {
        item.load = !item.load;
        this.updateBtnGroup('load', item.load);
      } else {
        item.unload = !item.unload;
        this.updateBtnGroup('unload', item.unload);
      }
    },

    updateBtnGroup(type, isActive) {
      console.log("CALL isActive " + isActive);

      const itemsToCheck = type === "unload" ? ['unload'] : ['load'];
      const areAllItemsTrue = (array, key) => array.every(item => item[key] === true || item[key] === undefined);

      if (isActive) {
        if (!this.btnGroup.includes(type)) {
          const allDocumentsValid = areAllItemsTrue(this.documentTableItems, itemsToCheck[0]);
          const allReferenceValid = areAllItemsTrue(this.referenceTableItems, itemsToCheck[0]);

          if (allDocumentsValid && allReferenceValid) {
            this.btnGroup = [...this.btnGroup, type];
          }
        }
      } else {
        this.btnGroup = this.btnGroup.filter(btn => btn !== type);
      }
    },

    loadAll() {
      const loadValue = this.btnGroup.includes("load");
      this.documentTableItems.forEach(item => item.load = loadValue);
      this.referenceTableItems.forEach(item => item.load = loadValue);
    },

    unloadAll() {
      const loadValue = this.btnGroup.includes("unload")
      this.documentTableItems.forEach(item => {
        if (Object.hasOwn(item, 'unload')) {
          item.unload = loadValue
        }
      });
      this.referenceTableItems.forEach(item => {
        if (Object.hasOwn(item, 'unload')) {
          item.unload = loadValue
        }
      });
    },

    copyToClipboard() {
      navigator.clipboard.writeText(this.token)
          .then(() => {
            this.tokenSnackbarVisible = true;
          })
          .catch(err => {
            console.error('Failed to copy text: ', err);
          });
    },
    async updateSettings() {
      this.loading = true;

      if (sessionStorage.getItem('userData')) {

        const userDataFromSessionStorage = JSON.parse(sessionStorage.getItem('userData'));

        const accountId = userDataFromSessionStorage.accountId;
        console.log("accountId : " + accountId)

        const settings = userDataFromSessionStorage.settings;
        console.log("settings : " + settings)

        if (accountId !== null && settings !== null) {

          this.loading = true;
          const mapToPayload = (items) => {
            const payload = items.reduce((acc, item) => {
              acc[item.name] = {
                load: item.load || false,
                ...(Object.hasOwn(item, 'unload') ? {unload: item.unload} : {})
              };
              return acc;
            }, {});

            return {settings: payload};
          };

// Example usage
          const settingsDataMap = mapToPayload(this.documentTableItems);
          console.log("settingsData : " + JSON.stringify(settingsDataMap));

          const settingsRefDataMap = mapToPayload(this.referenceTableItems);
          console.log("settingsRefDataMap : " + JSON.stringify(settingsRefDataMap));

          const settingsData = {
            settings: {
              customerOrder: settingsDataMap.settings.customerOrder.load,
              demand: settingsDataMap.settings.demand.load,
              demandIn: settingsDataMap.settings.demand.unload,
              salesReturn: settingsDataMap.settings.salesReturn.load,
              salesReturnIn: settingsDataMap.settings.salesReturn.unload,
              supply: settingsDataMap.settings.supply.load,
              supplyIn: settingsDataMap.settings.supply.unload,
              purchaseReturn: settingsDataMap.settings.purchaseReturn.load,
              enter: settingsDataMap.settings.enter.load,
              paymentIn: settingsDataMap.settings.paymentIn.load,
              paymentInIn: settingsDataMap.settings.paymentIn.unload,
              paymentOut: settingsDataMap.settings.paymentOut.load,
              paymentOutIn: settingsDataMap.settings.paymentOut.unload,
              cashIn: settingsDataMap.settings.cashIn.load,
              cashInIn: settingsDataMap.settings.cashIn.unload,
              cashOut: settingsDataMap.settings.cashOut.load,
              cashOutIn: settingsDataMap.settings.cashOut.unload,
              purchaseOrder: settingsDataMap.settings.purchaseOrder.load,
              loss: settingsDataMap.settings.loss.load,
              move: settingsDataMap.settings.move.load,
              //processing: settingsDataMap.settings.processing.load,
              good: settingsRefDataMap.settings.good.load,
              goodIn: settingsRefDataMap.settings.good.unload,
              company: settingsRefDataMap.settings.company.load,
              companyIn: settingsRefDataMap.settings.company.unload,
              contract: settingsRefDataMap.settings.contract.load,
              contractIn: settingsRefDataMap.settings.contract.unload,
              //processingPlan: settingsRefDataMap.settings.processingPlan.load,
              bundle: settingsRefDataMap.settings.bundle.load
            }
          };

          try {
            const response = await ApiService.updateUserSettings(accountId, settingsData,
                this.basic.username, this.basic.password);
            const userDataFromSessionStorage = JSON.parse(sessionStorage.getItem('userData'));
            userDataFromSessionStorage.settings = response.settings;
            sessionStorage.setItem('userData', JSON.stringify(userDataFromSessionStorage));
            this.loading = false;
            this.dialog = false;
            console.log(JSON.stringify(response));
          } catch (error) {
            this.loading = false;
            console.error("Error updating settings:", error);
          }
        }
      }
    }
  }
}
</script>
<style scoped>
</style>