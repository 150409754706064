<template>
  <v-app>
    <v-app-bar
      app
      class="px-3"
      density="compact"
      elevation="2"
    >
      <v-btn variant="flat" href="https://siriuslab.kz/" target="_blank" >SiriusLab синхронизация MC и 1C</v-btn>
      <v-spacer />
      <v-tabs
        v-model="activeTab"
        color="grey-darken-2"
        centered
      >
        <v-tab :key="0">
          Настройки обмена
        </v-tab>
        <v-tab :key="0">
          Плагин 1С
        </v-tab>
        <v-tab :key="1">
          Поддержка
        </v-tab>
      </v-tabs>
      <v-spacer />
      <v-spacer />
    </v-app-bar>
    <v-main class="bg-grey-lighten-3">
      <v-window v-model="activeTab">
        <v-window-item :value="0">
          <AppSettingsTab />
        </v-window-item>
        <v-window-item :value="1">
          <AppOnecTokenSettingsTab />
        </v-window-item>
        <v-window-item :value="2">
          <AppSupportTab />
        </v-window-item>
      </v-window>
    </v-main>
  </v-app>
</template>

<script>
import AppSettingsTab from "@/components/AppSettingsTab.vue";
import AppOnecTokenSettingsTab from "@/components/AppOnecTokenSettingsTab.vue";
import AppSupportTab from "@/components/AppSupportTab.vue";

export default {
  name: 'App',
  components: {
    AppSettingsTab,
    AppOnecTokenSettingsTab,
    AppSupportTab
  },
  data() {
    return {
      activeTab: 0,
    }
  }
};
</script>

<style>

</style>